import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
// import Layout from "../components/Layout";
import SEO from "../components/Seo";
import tw from "twin.macro";

const NotFoundPage = () => {
    const intl = useIntl();

    return (
        <>
            <SEO title={intl.formatMessage({ id: "18" })} />
            <div
                css={[
                    tw`h-screen w-screen bg-primary flex justify-center content-center flex-wrap`,
                ]}
            >
                <p css={[tw`font-montserrat text-white text-9xl font-bold`]}>404</p>
            </div>

            <div
                css={[tw`absolute w-screen bottom-0 mb-6 text-white text-center font-sans text-xl`]}
            >
                <span css={[tw`opacity-50`]}>{intl.formatHTMLMessage({ id: "19" })}</span>{" "}
                <Link to="/" css={[tw`border-b`]}>
                    {intl.formatHTMLMessage({ id: "20" })}
                </Link>
            </div>
        </>
    );
};

export default NotFoundPage;
